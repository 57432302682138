<template>
    <div class="root_wrapper">
        <Tinder class="tinder_wrapper" ref="tinder" key-name="id" :queue.sync="queue" :offset-y="0" @submit="onSubmit">
            <template slot-scope="scope">
                <div class="pic" :style="{ 'background-image': `url(${scope.data.id})` }">
                    <div class="info_user_pic">
                        <p class="p_action">Có hoạt động gần đây</p>
                        <h2 class="name_pic">{{ scope.data.fullname }} <span @click="showModal"><svg focusable="false" aria-hidden="true" role="presentation" viewBox="0 0 24 24" width="24px" height="24px" class="Expand"><path fill="#fff" d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"></path></svg></span></h2>
                        <p class="add_pic" v-if="scope.data.address != null"><img src="../assets/images/add.svg" alt=""> Sống tại {{ scope.data.address }}</p>
                        <p class="location_pic"><img src="../assets/images/location.svg" alt=""> Cách xa 5km</p>
                    </div>
                </div>
                <div id="modal-example" class="root-modal-info">
                    <div class="overlay" v-if="modal" @click="modal = false"></div>
                    <div class="modal modal_info" v-if="modal">
                        <div class="wp-modal-info">
                            <div class="modalpp">
                                <div class="wp_slider_info">
                                    <carousel ref="carousel" :perPage="1" :navigationEnabled="true" :paginationEnabled="true" :loop="false" :autoplay="false"  :navigateTo.sync="currentSlide">
                                        <slide v-for="(albumDt, index1) in scope.data.album" :key="index1">
                                            <img :src="albumDt.image" alt="Slide 1" class="img_slider_album">
                                        </slide>
                                    </carousel>
                                    <button class="close-modal" @click="hideModal"><img src="../assets/images/close.png" alt=""></button>
                                </div>
                                <div class="info_user_idol">
                                    <div class="info_detail">
                                        <div class="pd_lr_info">
                                            <h2 class="name_detail">{{ scope.data.fullname }}</h2>
                                            <p class="detail_us" v-if="scope.data.height != null"><img src="../assets/images/cm.svg" alt=""> {{ scope.data.height }}</p>
                                            <p class="detail_us" v-if="scope.data.address != null"><img src="../assets/images/add.svg" alt=""> Sống tại {{ scope.data.address }}</p>
                                            <p class="detail_us"><img src="../assets/images/location.svg" alt=""> Cách xa 5km</p>
                                        </div>
                                    </div>
                                    <div class="info_detail" v-for="(info, index2) in scope.data.declare_info" :key="index2">
                                        <div class="pd_lr_info" v-if="info.children.length > 0">
                                            <h3 class="pc_detail">{{ info.name }}</h3>
                                            <div v-for="(info1, index3) in info.children" :key="index3" class="wp_if_usder">
                                                <p class="nameinfo_p">{{ info1.name }}</p>
                                                <ul>
                                                    <li v-for="(info2, index4) in info1.children" :key="index4">{{ info2.name }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <span class="rewind-pointer" slot="rewind">Quay lại</span>
            <span class="nope-pointer" slot="nope">Không</span>
            <span class="super-pointer" slot="super">Siêu Thích</span>
            <span class="like-pointer" slot="like">Thích</span>
        </Tinder>
        <div class="btns" v-if="show == 1">
            <img src="../assets/images/rewind.png" @click="decide('rewind')">
            <img src="../assets/images/nope.png" @click="decide('nope')">
            <img src="../assets/images/super-like.png" @click="decide('super')">
            <img src="../assets/images/like.png" @click="decide('like')">
        </div>
        <div class="btns_arrow">
            <div class="wp_btn_showhide_arrow">
                <span class="hidebtn_arrow" v-if="checkshowArrow == 1" @click="hideArrow">Ẩn</span>
                <span class="showbtn_arrow" v-else @click="showArrow">Hiển thị</span>
            </div>
            <div class="btn_arrow_detail" v-if="checkshowArrow == 1">
                <span><img src="../assets/images/arrowleft.png" alt=""> Không</span>
                <span><img src="../assets/images/arrowright.png" alt=""> Thích</span>
                <span><img src="../assets/images/arrowup.png" alt=""> Mở thông tin</span>
                <span><img src="../assets/images/arrowdown.png" alt=""> Đóng thông tin</span>
                <span><img src="../assets/images/arrowenter.png" alt=""> Siêu thích</span>
                <span><img src="../assets/images/space.png" alt=""> Ảnh tiếp theo</span>
            </div>
        </div>
    </div>
</template>

<script>
import Tinder from "vue-tinder";
// import source from "@/bing";

import { Carousel, Slide } from 'vue-carousel';

export default {
    name: 'Home_Dating',
    components: { 
        Tinder,
        Carousel,
        Slide
    },
    data () {
        return {
            token: window.localStorage.getItem('token'),
            queue: [],
            offset: 0,
            history: [],
            modal: false,
            show: 1,
            checkshowArrow: 1,
            currentSlide: 0,
            totalSlides: 5,
            source: [],
            // source: [],
            // source: [
            //     "midmoon_ZH-CN4973736313",
            //     "MilkyWayCanyonlands_ZH-CN2363274510",
            //     "DaintreeRiver_ZH-CN2284362798",
            //     "TsavoGerenuk_ZH-CN2231549718",
            //     "ArroyoGrande_ZH-CN2178202888",
            //     "SouthernYellow_ZH-CN2055825919",
            // ],
        }
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keydown', this.handleKeySpace);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keydown', this.handleKeySpace);
    },
    created() {
        this.mock();
        this.getListUser()
    },
    methods: {
        mock(count, append = true) {
            this.axios.get(this.$api_listUser, {
                headers: {
                    Authorization: 'Bearer ' + this.token,
                },
            }).then((response) => {
                this.source = response.data.data.list_user;
                count = this.source.length
                const newItems = [];
                for (let i = 0; i < count; i++) {
                    newItems.push({
                        id_us: this.source[this.offset].id,
                        id: this.source[this.offset].avatar,
                        fullname: this.source[this.offset].fullname,
                        phone: this.source[this.offset].phone,
                        album: this.source[this.offset].album,
                        declare_info: this.source[this.offset].declare_info,
                    });
                    this.offset++;
                }
                if (append) {
                    this.queue = [...this.queue, ...newItems];
                } else {
                    this.queue = [...newItems, ...this.queue];
                }
                this.$set(this, 'queue', this.queue);
            })
        },
        onSubmit({ item }) {
            if (this.queue.length < 3) {
                this.mock();
            }
            this.history.push(item);
        },
        async decide(choice) {
            if (choice === "rewind") {
                if (this.history.length) {
                    this.$refs.tinder.rewind([this.history.pop()]);
                }
            } else {
                this.$refs.tinder.decide(choice);
            }
        },
        handleKeyDown(event) {
            if (event.key === "ArrowLeft") {
                this.swipeLeft();
            } else if (event.key === "ArrowRight") {
                this.swipeRight();
            } else if (event.key === "ArrowUp") {
                this.swipeUp();
            } else if (event.key === "ArrowDown") {
                this.swipeDown();
            } else if (event.key === "Enter") {
                this.swipeEnter();
            }
        },
        swipeLeft() {
            this.decide('nope')
            this.modal = false
            this.show = 1
        },
        swipeRight() {
            this.decide('like')
            this.modal = false
            this.show = 1
        },
        swipeUp() {
            this.modal = true
            this.show = 0
            this.currentSlide = 0
        },
        swipeDown() {
            this.modal = false
            this.show = 1
        },
        swipeEnter() {
            this.decide('super')
            this.modal = false
            this.show = 1
        },
        showModal () {
            this.modal = true,
            this.show = 0
            this.currentSlide = 0
        },
        hideModal () {
            this.modal = false,
            this.show = 1
        },
        hideArrow () {
            this.checkshowArrow = 0
        },
        showArrow () {
            this.checkshowArrow = 1
        },
        handleKeySpace (event) {
            if (event.key === " ") {
                this.currentSlide = (this.currentSlide + 1) % this.totalSlides;
            }
        },
        showAlert() {
            this.$swal({
                // icon: 'success error',
                position: 'top-end',
                icon: 'error',
                title: 'This is an alert from SweetAlert2',
                showConfirmButton: false,
                timer: 3000
            });
        },
        getListUser () {
            this.axios.get(this.$api_listUser, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listUser = response.data.data.list_user
            })
        }, 
    }
}
</script>
<style scoped>
</style>