<template>
    <div class="root_header">
        <div class="info_user">
            <div class="img_name" v-if="$route.path == '/'">
                <img :src="img" alt="">
                <span>{{ fullname }}</span>
            </div>
            <div class="img_name" v-else>
                <router-link to='/' title="">
                    <img src="../assets/images/logo.png" alt="">
                </router-link>
            </div>
            <div class="setting_user">
                <img src="../assets/images/logout.png" alt="" @click="logout">
                <router-link to='/profile' title="">
                    <img src="../assets/images/setting.png" alt="">
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Header_Dating',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            img: require('@/assets/images/pic.png'),
            fullname: ''
        }
    },
    components: {
        
    },
    created () {
        this.getListInfo()
    },
    methods: {
        getListInfo () {
            this.axios.get(this.$api_info, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.img = response.data.data.avatar
                this.fullname = response.data.data.fullname
            }).catch(e => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1000);
                }
            })
        },
        logout () {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.$api_logout,
                {

                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                localStorage.clear();
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
                setTimeout(() => {
                    window.location.href = '/'
                }, 1000);
            })
        },
    }
}
</script>
<style>
</style>