import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Home_Dating from '@/components/Home.vue';
import Profile_Dating from '@/components/Profile.vue';

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home_Dating',
            component: Home_Dating,
        },
        {
            path: '/profile',
            name: 'Profile_Dating',
            component: Profile_Dating,
        },
    ],
});