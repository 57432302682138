<template>
    <div class="root_profile">
        <div class="wp_setting">
            <form action="" method="post" @submit="editProfile">
                <div class="input_profile">
                    <label>Họ và tên</label>
                    <input type="text" v-model="fullname">
                </div>
                <div class="input_profile">
                    <label>Số điện thoại</label>
                    <input type="text" v-model="phone">
                </div>
                <div class="input_profile">
                    <label>Địa chỉ</label>
                    <input type="text" v-model="address">
                </div>
                <div class="input_profile">
                    <label>Chiều cao</label>
                    <input type="text" v-model="height">
                </div>
                <div class="input_profile">
                    <label>Giới tính</label>
                    <select v-model="sex">
                        <option value="0">Nam</option>
                        <option value="1">Nữ</option>
                    </select>
                </div>
                <div class="input_profile">
                    <div class="img_preview">
                        <img :src="avatar" alt="Preview"/>
                    </div>
                    <div class="file-input file-input_st">
                        <input type="file" class="file_img" id="file_img" @change="onFileChange" accept="image/*">
                        <span class='button'>Ảnh đại diện</span>
                        <span class='label'>Chọn ảnh đại diện</span>
                    </div>
                </div>
                <div class="input_profile">
                    <label>Album</label>
                </div>
                <div class="imageAbl">
                    <div v-for="(image, index) in fixedAlbum" :key="index" class="image_ab">
                        <div>
                            <img v-if="image" :src="image.image" alt="Image Preview" />
                            <span v-if="image" class="edit_imgst" @click="editImage(index)"><img src="../assets/images/pen.png" alt=""></span>
                            <div v-else class="image_addAbl" @click="triggerFileInput">+</div>
                            <span v-if="image" @click="deleteImgAb(image.id)" class="removeImgAb"><img src="../assets/images/delete.png" alt=""></span>
                        </div>
                    </div>
                </div>
                <input type="file" id="image_upload_album" ref="fileInput" style="display: none;" @change="handleFileChange" accept="image/*" />
                <div class="input_profile">
                    <div class="wp_info_user" v-for="(item, index) in listInfoUs" :key="index">
                        <h3>{{ item.name }}</h3>
                        <div class="menu_2" v-for="(item1, index1) in item.children" :key="index1">
                            <h4>{{ item1.name }}</h4>
                            <div class="radio-buttons">
                                <div class="button" v-for="(item2, index2) in item1.children" :key="index2">
                                    <div class="rt_wp_ip">
                                        <input type="radio" :value="item2.id" v-model="selectedValues[item1.id]">
                                        <span>{{ item2.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input_btnsave">
                    <button type="submit">Lưu</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Profile_Dating',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            listInfo: [],
            avatar: require('@/assets/images/add-image.png'),
            nameImg: 'Chưa có hình ảnh',
            fullname: '',
            phone: '',
            address: '',
            sex: '',
            height: '',
            album: [],
            editingIndex: null,
            selectedFile: '',
            other: '',
            itemImageUpdate: '',
            listInfoUs: [],
            selectedValues: {}
        }
    },
    components: {
        
    },
    computed: {
        fixedAlbum() {
            const fixed = [...this.album];
            while (fixed.length < 6) {
                fixed.push(null);
            }
            return fixed;
        },
    },
    created () {
        this.getListInfo()
        this.getListInfoChose()
    },
    methods: {
        getListInfo () {
            this.axios.get(this.$api_info, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.fullname = response.data.data.fullname
                this.phone = response.data.data.phone
                this.address = response.data.data.address
                this.sex = response.data.data.sex
                this.height = response.data.data.height
                this.album = response.data.data.album
                this.avatar = response.data.data.avatar
            }).catch(e => {
                if (e.response.status === 401) {
                    localStorage.clear();
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 2000);
                }
            })
        },
        onFileChange(event) {
            const file = event.target.files[0];
            if (file && file.type.startsWith("image/")) {
                this.selectedFile = file;
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    this.avatar = e.target.result;
                };
                reader.readAsDataURL(file);
            } else {
                alert("Vui lòng chọn một tệp hình ảnh.");
            }
        },
        triggerFileInput() {
            this.editingIndex = null;
            this.$refs.fileInput.click();
        },
        editImage(index) {
            this.editingIndex = index;
            this.$refs.fileInput.click();
        },
        removeImage(index) {
            this.album.splice(index, 1);
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file && file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64 = e.target.result;
                    if (this.editingIndex !== null) {
                        const originalItem = this.album[this.editingIndex];
                        const updatedItem = {
                            ...originalItem,
                            file,
                            image: base64,
                        };
                        const imageId = this.album[this.editingIndex]?.id;
                        this.itemImageUpdate = file
                        this.$set(this.album, this.editingIndex, updatedItem);
                        this.postImage(imageId, this.itemImageUpdate)
                    } else {
                        this.itemImageUpdate = file
                        this.album.push({ file, image: base64 });
                        this.postImage(0, this.itemImageUpdate)
                    }
                    this.editingIndex = null;
                    event.target.value = null;
                };
                reader.readAsDataURL(file);
            }
        },
        editProfile (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            const form = new FormData();
            const parentIds = [];
            const childIds = [];
            for (const [key, value] of Object.entries(this.selectedValues)) {
                parentIds.push(Number(key));
                childIds.push(value);
            }
            // if (parentIds && parentIds.length > 0) {
            //     for (let i = 0; i < parentIds.length; i++) {
            //         form.append('parent_id[]', parentIds[i]);
            //     }
            // }
            // if (childIds && childIds.length > 0) {
            //     let declare_id = childIds.filter(element => element !== null);
            //     for (let i = 0; i < declare_id.length; i++) {
            //         form.append('declare_id[]', declare_id[i]);
            //     }
            // }

            if (parentIds && parentIds.length > 0 && childIds && childIds.length > 0) {
                for (let i = 0; i < childIds.length; i++) {
                    if (childIds[i] !== null) {
                        form.append('parent_id[]', parentIds[i]);
                        form.append('declare_id[]', childIds[i]);
                    }
                }
            }

            form.append('fullname', this.fullname);
            form.append('phone', this.phone);
            form.append('address', this.address);
            form.append('height', this.height);
            form.append('sex', this.sex);
            form.append('avatar', this.selectedFile);
            this.axios.post(this.$api_edit,
                form,
                {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        },
        convertBase64ToFile(base64String, id) {
            const arr = base64String.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const byteCharacters = atob(arr[1]);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                const byteArray = new Uint8Array(1024);
                for (let i = 0; i < 1024 && offset + i < byteCharacters.length; i++) {
                byteArray[i] = byteCharacters.charCodeAt(offset + i);
                }
                byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, { type: mime });
            const fileName = `${id}_image_from_base64.png`;
            return new File([blob], fileName, { type: mime });
        },
        convertLinkToFile(imageUrl) {
            return fetch(imageUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const fileName = imageUrl.split('/').pop();
                    return new File([blob], fileName, { type: blob.type });
                })
                .catch((error) => {
                    console.error('Lỗi khi chuyển đổi URL sang file:', error);
                    return null;
                });
        },
        deleteImgAb (id) {
            $('.loading_show').show().fadeIn(10)
            this.axios.post(this.$api_deleteImg, {
                image_id: id,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.other = res
                const index = this.album.findIndex(item => item && item.id === id);
                if (index !== -1) {
                    this.album.splice(index, 1);
                }
            })
        },
        postImage (id, image) {
            $('.loading_show').show().fadeIn(10)
            const formData = new FormData();
            if (typeof id != 'undefined' || id != 0) {
                formData.append('id', id);
            }
            formData.append('image', image);
            this.axios.post(this.$api_addEditImg,
                formData,
                {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then(res => {
                $('#loading_show').css('display', 'none')
                this.album = res.data.data.album_details;
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                this.other = e
            })
        },
        getListInfoChose () {
            this.axios.get(this.$api_listInfoChose, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listInfoUs = response.data.data
                this.listInfoUs.forEach((item) => {
                    item.children.forEach((item1) => {
                        const checkedItem = item1.children.find((child) => child.check === 1);
                        this.$set(this.selectedValues, item1.id, checkedItem ? checkedItem.id : null);
                    });
                });
            })
        }, 
    }
}
</script>
<style>
</style>